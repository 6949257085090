// @flow
import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { paths } from '../../config';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import { IntroText } from '../styles';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    padding: 80px 0;
  }
`;

const StyledImg = styled(Img)`
  min-height: 327px;
  margin-top: -24px;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    width: 809px;
    height: auto;
    margin-left: -60px;
  }

  @media (min-width: 992px) {
    margin-left: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 40px;
  }
`;

type Props = {
  data: {
    illustrationBalance: {
      childImageSharp: {
        fluid: any,
      },
    },
  },
};

const NotFoundPage = ({
  data: {
    illustrationBalance,
  },
}: Props) => (
  <Layout>
    <SEO
      title="404 Page not found - ethical.net"
      canonical=""
      meta={[{
        name: 'robots',
        content: 'noindex,follow',
      }]}
    />
    <Wrapper className="row align-items-md-center">
      <div className="col-md-6 col-lg-5">
        <h1 className="h1-plus">404 error</h1>
        <IntroText>Sorry, that page can’t be found.</IntroText>
        <IntroText>You may have mistyped the address or the page may have moved.</IntroText>
        <IntroText>
          You may find these pages useful:
        </IntroText>
        <IntroText as="div">
          <ul>
            <li>
              <Link to="/">ethical.net homepage</Link>
            </li>
            <li>
              <Link to={paths.resources}>ethical resources repository</Link>
            </li>
            <li>
              <Link to={paths.blog}>ethical.net blog archive</Link>
            </li>
            <li>
              <Link to={paths.contact}>contact us</Link>
            </li>
          </ul>
        </IntroText>
      </div>
      <div className="col-md-6 col-lg-5">
        <StyledImg fluid={illustrationBalance.childImageSharp.fluid} alt="" />
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    illustrationBalance: file(relativePath: { eq: "illustration-balance.png" }) {
      childImageSharp {
        fluid(maxWidth: 809) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
